/**
 *  Main Menu Json
 */
export default [
   {
      "menu_title": "Home",
      "type": "subMenu",
      "path": "/",
      "icon": "home",
      "child_routes": [
         {
            "path": "/",
            "menu_title": "Landing 1",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index2",
            "menu_title": "Landing 2",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index3",
            "menu_title": "Landing 3",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index4",
            "menu_title": "Landing 4",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index5",
            "menu_title": "Landing 5",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index6",
            "menu_title": "Landing 6",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index7",
            "menu_title": "Landing 7",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index8",
            "menu_title": "Landing 8",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index9",
            "menu_title": "Landing 9",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ]
   },
   {
      "menu_title": "Pages",
      "path": "/",
      "mega": true,
      "icon": "party_mode",
      "type": "subMenu",
      "child_routes": [
         {
            "path": "/",
            "menu_title": "Company",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/about-us",
                  "menu_title": "About US",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/about-us2",
                  "menu_title": "About US 2",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/career",
                  "menu_title": "Career",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/career-single",
                  "menu_title": "Career Single",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },
         {
            "path": "/",
            "menu_title": "Blogs",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/blog-card",
                  "menu_title": "Blog Card",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/blog-listing-1",
                  "menu_title": "Blog Listing 1",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/blog-listing-2",
                  "menu_title": "Blog Listing 2",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/blog-single",
                  "menu_title": "Blog Single",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },
         {
            "path": "/",
            "menu_title": "Portfolio",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/portfolio",
                  "menu_title": "Portfolio Card",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/portfolio-single/Branding/6",
                  "menu_title": "Portfolio Single",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },
         {
            "path": "/",
            "menu_title": "Contacts",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/contact-us",
                  "menu_title": "Contact 1",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/contact-us-1",
                  "menu_title": "Contact 2",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },
         {
            "path": "/",
            "menu_title": "Account",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/sign-in",
                  "menu_title": "Sign In",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/sign-in-1",
                  "menu_title": "Sign In 2",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/sign-up",
                  "menu_title": "Sign UP",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/forgot-password",
                  "menu_title": "Forgot Password",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },
         {
            "path": "/",
            "menu_title": "Utilities",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/pricing",
                  "menu_title": "Pricing",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/faq",
                  "menu_title": "Faq",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/maintenance",
                  "menu_title": "Maintenance",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/coming-soon",
                  "menu_title": "Coming Soon",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/page-not-found",
                  "menu_title": "Error 404",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/privacy-policy",
                  "menu_title": "Privacy & Policy",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/term-condition",
                  "menu_title": "Terms & Conditions",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },
      ],
   },
   {
      "menu_title": "Shop",
      "type": "subMenu",
      "path": "/",
      "icon": "home",
      "child_routes": [
         {
            "path": "/product-grid",
            "menu_title": "Product Grid",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/product-list",
            "menu_title": "Product List",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/product-single/Women/1",
            "menu_title": "Product Single",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/cart",
            "menu_title": "Cart",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/checkout",
            "menu_title": "Checkout",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/order-complate",
            "menu_title": "Order Completed",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ]
   },
   {
      "menu_title": "Features",
      "type": "subMenu",
      "path": "/",
      "icon": "home",
      "child_routes": [
         {
            "path": "/accordion",
            "menu_title": "Accordion",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/blog-list",
            "menu_title": "Blog",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/number-counter",
            "menu_title": "Counter",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/feature-box",
            "menu_title": "Feature Box",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/hero-banner",
            "menu_title": "Hero Banner",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/gallery",
            "menu_title": "Lightbox Gallery",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/pricing-table",
            "menu_title": "Price Table",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/video-lightbox",
            "menu_title": "Video Lightbox",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/team",
            "menu_title": "Team",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/testimonial",
            "menu_title": "Testimonial",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ]
   },
]
