/**
 *  Portfolio Project
 */
export default [
    {
        "id":1,
        "name":"Web Development",
        "images":"01.jpg",
        "description":"It has survived not only five centuries, but also the leap typesetting habent claritatem insitamconsequat duis autem facilisis at vero eros nibh euismod tincidunt.",
        "category":["Design", "Development"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" November 23, 2019"
    },   
    {
        "id":2,
        "name":"Estates CAFM Stabilisation",
        "images":"02.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Marketing", "Development"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" October 20, 2018"
    },
    {
        "id":3,
        "name":"Deploy MDM Service",
        "images":"03.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Branding", "Design"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" January 15, 2020"
    },
    {
        "id":4,
        "name":"Scottish Crop Map",
        "images":"04.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Design", "Marketing"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" November 17, 2018"
    },
    {
        "id":5,
        "name":"DRS Website Development",
        "images":"05.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Marketing"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" December 05, 2019"
    },
    {
        "id":6,
        "name":"Digital Marketing MicroMasters",
        "images":"06.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Branding", "Design"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" February 18, 2019"
    },
    {
        "id":7,
        "name":"Annual DR Review",
        "images":"02.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Branding", "Design"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" March 15, 2017"
    },  
    {
        "id":8,
        "name":"Langhill Farm G12",
        "images":"05.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Marketing"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" January 10, 2010"
    },
    {
        "id":9,
        "name":"EdWeb Development",
        "images":"03.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Design"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" February 15, 2017"
    },
    {
        "id":10,
        "name":"New Business School",
        "images":"01.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Development"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" November 10, 2018"
    },
    {
        "id":11,
        "name":"UniDesk for RCA",
        "images":"04.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Branding"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" January 02, 2019"
    },
    {
        "id":12,
        "name":"BI Tools Framework",
        "images":"02.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Development"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" March 21, 2019"
    },
	{
        "id":13,
        "name":"Communications and Events",
        "images":"03.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Branding"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" February 27, 2018"
    },  
    {
        "id":14,
        "name":"Supply chains Living Lab projects",
        "images":"06.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Branding"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" January 15, 2016"
    },
    {
        "id":15,
        "name":"Decommission eCommerce Software",
        "images":"02.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Marketing", "Development"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" March 14, 2017"
    },
    {
        "id":16,
        "name":"CAHSS WAM Deployment",
        "images":"05.jpg",
        "description":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.",
        "category":["Marketing"],
        "client":"Your client name",
        "createdby":"Skytheme",
        "projectcomplated":" January 12, 2018"
    }
]