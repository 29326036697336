import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Pricingplan5 extends Component {
    render() {
        return (
          
              <div className="row">
                <div className="col-12">
                  <div className="tab-content mt-8" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="nav-tab-1">
                      <div className="row align-items-center">
                        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                          {/* Card */}
                          <div className="card border-0 shadow">
                            {/* Body */}
                            <div className="card-body py-8 px-6">
                              {/* Badge */}
                              <div className="text-center mb-5"> <span className="badge shadow">
                                  <span className="h6 text-uppercase">Basic</span>
                                </span>
                              </div>
                              <div className="mb-5">
                                <img className="img-fluid" src={require(`../../assets/images/svg/01.svg`)} alt="" />
                              </div>
                              {/* Features */}
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Free Custom Domain</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Monthly updates</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Outstanding Support</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Happy Customers</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              {/* Price */}
                              <div className="d-flex justify-content-center mt-5"> <span className="h3 mb-0 mt-2">$</span>
                                <span className="price display-3 text-primary font-w-6">29</span>
                              </div>
                              {/* Text */}
                              <p className="text-center text-muted">Per user</p>
                              {/* Button */} <Link to="/" className="btn btn-block btn-primary mt-5">
                                Choose Packege
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          {/* Card */}
                          <div className="card border-0 shadow">
                            {/* Body */}
                            <div className="card-body py-8 px-6">
                              {/* Badge */}
                              <div className="text-center mb-5"> <span className="badge shadow">
                                  <span className="h6 text-uppercase">Standard</span>
                                </span>
                              </div>
                              <div className="mb-5">
                                <img className="img-fluid" src={require(`../../assets/images/svg/02.svg`)} alt="" />
                              </div>
                              {/* Features */}
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Free Custom Domain</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Monthly updates</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Outstanding Support</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Happy Customers</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              {/* Price */}
                              <div className="d-flex justify-content-center mt-5"> <span className="h3 mb-0 mt-2">$</span>
                                <span className="price display-3 text-primary font-w-6">59</span>
                              </div>
                              {/* Text */}
                              <p className="text-center text-muted">Per user</p>
                              {/* Button */} <Link to="/" className="btn btn-block btn-primary mt-5">
                                Choose Packege
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          {/* Card */}
                          <div className="card border-0 shadow">
                            {/* Body */}
                            <div className="card-body py-8 px-6">
                              {/* Badge */}
                              <div className="text-center mb-5"> <span className="badge shadow">
                                  <span className="h6 text-uppercase">Extended</span>
                                </span>
                              </div>
                              <div className="mb-5">
                                <img className="img-fluid" src={require(`../../assets/images/svg/02.svg`)} alt="" />
                              </div>
                              {/* Features */}
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Free Custom Domain</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Monthly updates</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Outstanding Support</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Happy Customers</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              {/* Price */}
                              <div className="d-flex justify-content-center mt-5"> <span className="h3 mb-0 mt-2">$</span>
                                <span className="price display-3 text-primary font-w-6">89</span>
                              </div>
                              {/* Text */}
                              <p className="text-center text-muted">Per user</p>
                              {/* Button */} <Link to="/" className="btn btn-block btn-primary mt-5">
                                Choose Packege
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="nav-tab-2">
                      <div className="row align-items-center">
                        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                          {/* Card */}
                          <div className="card border-0 shadow">
                            {/* Body */}
                            <div className="card-body py-8 px-6">
                              {/* Badge */}
                              <div className="text-center mb-5"> <span className="badge shadow">
                                  <span className="h6 text-uppercase">Basic</span>
                                </span>
                              </div>
                              <div className="mb-5">
                                <img className="img-fluid" src={require(`../../assets/images/svg/01.svg`)} alt="" />
                              </div>
                              {/* Features */}
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Free Custom Domain</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Monthly updates</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Outstanding Support</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Happy Customers</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              {/* Price */}
                              <div className="d-flex justify-content-center mt-5"> <span className="h3 mb-0 mt-2">$</span>
                                <span className="price display-3 text-primary font-w-6">29</span>
                              </div>
                              {/* Text */}
                              <p className="text-center text-muted">Per user</p>
                              {/* Button */} <Link to="/" className="btn btn-block btn-primary mt-5">
                                Choose Packege
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          {/* Card */}
                          <div className="card border-0 shadow">
                            {/* Body */}
                            <div className="card-body py-8 px-6">
                              {/* Badge */}
                              <div className="text-center mb-5"> <span className="badge shadow">
                                  <span className="h6 text-uppercase">Standard</span>
                                </span>
                              </div>
                              <div className="mb-5">
                                <img className="img-fluid" src={require(`../../assets/images/svg/02.svg`)} alt="" />
                              </div>
                              {/* Features */}
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Free Custom Domain</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Monthly updates</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Outstanding Support</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Happy Customers</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              {/* Price */}
                              <div className="d-flex justify-content-center mt-5"> <span className="h3 mb-0 mt-2">$</span>
                                <span className="price display-3 text-primary font-w-6">59</span>
                              </div>
                              {/* Text */}
                              <p className="text-center text-muted">Per user</p>
                              {/* Button */} <Link to="/" className="btn btn-block btn-primary mt-5">
                                Choose Packege
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          {/* Card */}
                          <div className="card border-0 shadow">
                            {/* Body */}
                            <div className="card-body py-8 px-6">
                              {/* Badge */}
                              <div className="text-center mb-5"> <span className="badge shadow">
                                  <span className="h6 text-uppercase">Extended</span>
                                </span>
                              </div>
                              <div className="mb-5">
                                <img className="img-fluid" src={require(`../../assets/images/svg/02.svg`)} alt="" />
                              </div>
                              {/* Features */}
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Free Custom Domain</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Monthly updates</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Outstanding Support</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              <div className="d-flex align-items-start justify-content-between">
                                {/* Text */}
                                <p>Happy Customers</p>
                                {/* Check */}
                                <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                </div>
                              </div>
                              {/* Price */}
                              <div className="d-flex justify-content-center mt-5"> <span className="h3 mb-0 mt-2">$</span>
                                <span className="price display-3 text-primary font-w-6">89</span>
                              </div>
                              {/* Text */}
                              <p className="text-center text-muted">Per user</p>
                              {/* Button */} <Link to="/" className="btn btn-block btn-primary mt-5">
                                Choose Packege
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
      
        );
    }
}

export default Pricingplan5;