import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Pricingplan3 extends Component {
    render() {
        return (
          
              <div className="row align-items-center">
                <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                  {/* Card */}
                  <div className="card border-0 hover-translate">
                    {/* Body */}
                    <div className="card-body py-11 px-6">
                      {/* Features */}
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Free Custom Domain</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Monthly updates</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Outstanding Support</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Happy Customers</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      {/* Badge */}
                      <div className="text-center my-5"> <span className="badge shadow badge-primary">
                          <span className="h6 text-uppercase">Basic</span>
                        </span>
                      </div>
                      {/* Price */}
                      <div className="d-flex justify-content-center"> <span className="h2 mb-0 mt-2">$</span>
                        <span className="price display-2">29</span>
                        <span className="h2 align-self-end text-primary">/mo</span>
                      </div>
                      {/* Text */}
                      <p className="text-center text-muted mb-6 mb-md-8">Per user</p>
                      {/* Button */} <Link to="/" className="btn btn-block btn-outline-primary mt-5">
                        Choose Packege
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                  {/* Card */}
                  <div className="card bg-primary shadow border-0">
                    {/* Body */}
                    <div className="card-body py-11 px-6">
                      {/* Features */}
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p className="text-light">Free Custom Domain</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p className="text-light">Monthly updates</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p className="text-light">Outstanding Support</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p className="text-light">Happy Customers</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                        </div>
                      </div>
                      {/* Badge */}
                      <div className="text-center my-5"> <span className="badge shadow badge-light">
                          <span className="h6 text-uppercase">Standard</span>
                        </span>
                      </div>
                      {/* Price */}
                      <div className="d-flex justify-content-center text-white"> <span className="h2 mb-0 mt-2">$</span>
                        <span className="price display-2">59</span>
                        <span className="h2 align-self-end">/mo</span>
                      </div>
                      {/* Text */}
                      <p className="text-center text-light mb-6 mb-md-8">Per user</p>
                      {/* Button */} <Link to="/" className="btn btn-block btn-outline-light mt-5">
                        Choose Package
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  {/* Card */}
                  <div className="card border-0 hover-translate">
                    {/* Body */}
                    <div className="card-body py-11 px-6">
                      {/* Features */}
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Free Custom Domain</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Monthly updates</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Outstanding Support</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        {/* Text */}
                        <p>Happy Customers</p>
                        {/* Check */}
                        <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                        </div>
                      </div>
                      {/* Badge */}
                      <div className="text-center my-5"> <span className="badge shadow badge-primary">
                          <span className="h6 text-uppercase">Extended</span>
                        </span>
                      </div>
                      {/* Price */}
                      <div className="d-flex justify-content-center"> <span className="h2 mb-0 mt-2">$</span>
                        <span className="price display-2">89</span>
                        <span className="h2 align-self-end text-primary">/mo</span>
                      </div>
                      {/* Text */}
                      <p className="text-center text-muted mb-6 mb-md-8">Per user</p>
                      {/* Button */} <Link to="/" className="btn btn-block btn-outline-primary mt-5">
                        Choose Package
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
        
         
        );
    }
}

export default Pricingplan3;