import React, { Component } from 'react';

class BrandLogo extends Component {
    render() {
        return (

            <div>
                <div className="row">
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/07.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/08.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/09.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/10.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/11.png`)} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/12.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/08.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/09.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/10.png`)} alt="" />
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default BrandLogo;